
<template>
  <div class="page-content w-100">
    <div class="row">
      <div class="col-sm-12">
        <p class="text-center page-heading">Medical Records</p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 usersStyle">
        <div class="row mt-3" style="margin: 5px;">
          <div class="col-8 col-lg-10">
            <h4 class="mt-2">
              <span class="">Upload Records</span>
            </h4>
          </div>
          <div class="col-4 col-lg-2">
            <router-link to="/user/records">
                <p class="closeButton mt-2"><font-awesome-icon :icon="['fas','times']" /></p>
          
            </router-link>
          </div>
          <div class="hr-border"></div>
        </div>
        <div v-if="user">
        <div class="row mt-2"  v-for="index in userRecords" :key="index" style="margin:5px;">
          <div class="col-sm-12">
            <label class="nameofpublic"><b>{{ index.firstName }} {{ index.lastName }}</b></label>
            <p class="nonDoctorContent mt-2">Consulted a non-RxIx doctor? Upload your prescriptions and discharge
              summaries. Help your consulting doctor get a complete picture of your
              health condition.</p>
            <p class="nonDoctorContent mt-2 mb-0"> Note: Only images files are allowed.</p>
          </div>
        </div>
        </div>
        <div class="row mt-2" style="margin:5px;" v-if="family">
          <div class="col-sm-12" v-for="family in familyRecords" :key="family">
            <h4 class="nameofpublic"><b>{{ family.firstName }} {{ family.lastName }}</b></h4>
            <p class="nonDoctorContent mt-2">Consulted a non-RxIx doctor? Upload your prescriptions and discharge
              summaries. Help your consulting doctor get a complete picture of your
              health condition.</p>
            <p class="nonDoctorContent mt-2">Note: Only images files are allowed.</p>
          </div>
        </div>

        <div class="row mt-2" v-for="(records,index) in upload_Record" :key="index" style="margin:5px;">
          <div class="col-lg-3 col-12 my-2">
            <div class="form-group">
              <label class="nameofpublic" for="">Doctor's name</label>
              <div class="form-group input-group">
                    <span class="input-group-addon">Dr.</span>
                    <input type="text" class="form-control " name="doctorname" v-model="records.doctor_name" @input="validateInput"  id="formName" >
                  </div>
              
            </div>
          </div>
          <div class="col-lg-3 col-12 my-2">
            <div class="form-group">
              <label class="nameofpublic" for="datepicker">Consultation date</label>
              <input type="date" id="datepicker" :max="new Date().toISOString().split('T')[0]" v-model="records.consultation_date" name="consultdate" class="form-control">
            </div>
          </div>
          <div class="col-lg-5 col-10 my-2">
            <!-- <button class="btn btn-color text-white border-curve" type="file" id="files" @click="onFileChanged($event)"
              style="margin-top:25px;">Upload
              prescription</button> -->
              <label class="nameofpublic" for="datepicker">Upload prescription</label>
            <input type="file" class="form-control" @change="fileSelect($event,index)">
          </div>
          <div class="col-lg-1 col-2 mt-4 my-2">
            <span class="text-align text-center plus-icon" style="font-size:30px"  >
                <font-awesome-icon  @click="isShowAddConsult()" :class="[!isConsultationDataValid ? 'disableCreateBtn' : '']" :icon='["fas", "plus-circle"]' />
            </span>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12" style="margin-bottom:20px;">
            <div class="text-center">
              <button class="btn btn-blue-color border-curve text-white"  :disabled="!isConsultationDataValid"   @click="uploadData()">Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from "sweetalert2/dist/sweetalert2.js";
// import { Image } from 'image-js';
// import ImageCompressor from 'image-compressor';
export default {
  data() {
    return {
      message: 'Upload files here:',
      files: false,
      consultation_date: null,
      upload_Record: [
        {
        doctor_name: "",
          consultation_date: "",
          uploadImage: "",
        },
      ],
      userRecords: [],
      userData: [],
      publicUserID: {},
      uploadImage: null,
      family:"",
      user:"",
      familyRecords:[],
      maxFileSize: 200 * 1024, // 200KB in bytes,
      doctorData:null
    };
  },
  mounted() {
    const queryParameters = this.$route.path;

    // Split the queryParameters string using '/' as the delimiter
    const parts = queryParameters.split('/');
    
    // Find the 'family' part
    const familyPart = parts.find(part => part === 'family');
    const userPart = parts.find(part=>part==="user");
    
    if (familyPart) {
      this.family=familyPart
    } 
    else if(userPart)  {
    this.user=userPart
    }
  },
  created: function () {
    this.getuploadData();
    this.recordsData();
    this.uploadGetData()
    this.isUser = this.$route.path.split('/')[3];
    this.userId = this.$route.path.split('/')[3];
    this.userIds = this.$route.params.id
  
    this.isRole = this.$route.path.split('/')[4];
    if(this.isRole==='user'){
      this.userId= this.$route.path.split('/')[3];
    }
    else{
      this.familyId = this.$route.path.split('/')[3]
    }

  },
  computed:{
    isConsultationDataValid() {
  if (this.upload_Record.length === 0) {
    return false; // No records to validate
  }
  for (let i = 0; i < this.upload_Record.length; i++) {
    const record = this.upload_Record[i];
    if (
      !record.doctor_name ||
      !record.consultation_date ||
      !record.uploadImage
    ) {
      return false; 
    }
  }
  return true; 
}
  },
  methods: {

    validateInput() {
    this.doctor_name = this.doctor_name.replace(/[^a-zA-Z.,_\s]/g, '');
},
    isShowAddConsult() {
      this.upload_Record.push({
        doctor_name: "",
        consultation_date: null,
        uploadImage: null,
      });
    },
    recordsData() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.publicUserID = localStorage.getItem('publicSignUpData');
      axios
      .get(`${BASE_API_URL}/public-user/${this.publicUserID}/one`)
        .then((response) => {
          if (response?.data) {
            this.userRecords = [response.data];
          }
        })
      axios
        .get(`${BASE_API_URL}/family-member/${this.userIds}`)
        .then((response) => {
          if (response?.data) {

           this.familyRecords = response.data.filter((filteredValue) => {
                            return Number(filteredValue.id) === Number(this.userIds)
                        })
          }
        })
    },
  fileSelect(event,index) {
  this.upload_Record[index].uploadImage = event.target.files?.[0];
},

    async compressImage(file) {
  return new Promise((resolve) => {
    const quality = 0.8; // Adjust quality as needed
    const maxWidth = 1000; // Adjust max width as needed
    const maxHeight = 1000; // Adjust max height as needed
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = maxWidth;
      canvas.height = maxHeight;
      ctx.drawImage(image, 0, 0, maxWidth, maxHeight);

      canvas.toBlob(
        (blob) => {
          const compressedFile = new File([blob], file.name, {
            type: 'image/jpeg', // Change the type as needed
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        },
        'image/jpeg', // Change the type as needed
        quality
      );
    };
    image.src = URL.createObjectURL(file);
  });
},

  changeDoctorFirstName(name){
    return name.charAt(0).toUpperCase() + name.slice(1);
    },
async uploadData() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  for (let i = 0; i < this.upload_Record.length; i++) {
  const compressedImage = await this.compressImage(this.upload_Record[i].uploadImage);
  if (compressedImage.size < 22240) {
    this.$swal("Image not clear. Please Upload image of better quality.");
    return;
  }
  let formData = new FormData();
  formData.append('doctor_name',"Dr. "+ this.changeDoctorFirstName(this.upload_Record[i].doctor_name.toLowerCase()));
  formData.append('consultation_date', this.upload_Record[i].consultation_date);     
  formData.append('file', compressedImage); // Use the compressed image
  formData.append('user_id', this.userRecords[0].id);
  formData.append('family_id', this.familyId);
  formData.append('doctor_id',this.doctorData)
  if (this.isRole === 'user') {
    formData.append('user_id', this.userId);
  } else {
    formData.append('family_id', this.familyId);
  }
  axios
    .post(`${BASE_API_URL}/upload-records`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (response?.data) {
        let UploadedData= response.data;
        const userUploadData ={
          caseSheet:UploadedData.caseSheet,
          consultation_date:UploadedData.consultation_date,
          createdAt:UploadedData.createdAt,
          date:UploadedData.data,
          doctor_name:UploadedData.doctor_name,
          family_id:UploadedData.family_id,
          id:UploadedData.id,
          mobile:UploadedData.mobile,
          updateAt:UploadedData.updateAt,
          upload_prescription:UploadedData.upload_prescription,
          user_id :UploadedData.user_id
        }

        Swal.fire({
              title: "File uploaded",
              confirmButtonColor: "#7066e0",
              confirmButtonText: "OK",
              }).then((result) => {
            if (result.isConfirmed) {
              window.scrollTo(0, 0);
            this.$router.push({path: `/user/records`, query : userUploadData });
             }
            });
      }
    })
  }
},
    getuploadData() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/upload-records`)
                .then((response) => {
                    if (response?.data) {
                        this.filteredUploadRecord = response.data.filter((filteredValue) => {
                            return filteredValue?.family_id?.id === this.upFamily?.id
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
    uploadGetData() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/upload-records`)
        .then((response) => {
          if (response?.data) {
            this.upload = response.data;
            response.data.map((data) => {
              // localStorage.setItem('upload',data.id)
              this.uploadValue = data
            })
          }
        })
    }
  },
};
</script>
<style>
.plus-icon{
  cursor: pointer;
}
.disableCreateBtn{
  color:  #dddddd !important;
  pointer-events: none;
  cursor: auto; 
}
.nameofpublic {
  font-size: 21px;
}

.col-sm-12.usersStyle {
  margin: 0px 0px 0px 10px;
  background-color: #ffff;
  border-radius: 10px;
  padding-bottom: 15px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%);

}

.nonDoctorContent {
  font-size: 18px;
}
@media screen and (max-width:991px) {
  .col-sm-12.usersStyle {
    margin: auto;
    padding: auto;
    box-shadow: none;
  }
}
</style>













